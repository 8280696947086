import { Routes } from '@angular/router';

export const contentwebpage: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule),
    data: {
      breadcrumb: "Dashboard",
    },
  },
  {
    path: "sample-page",
    loadChildren: () => import("../../components/suscripcion/sample-page.module").then((m) => m.SamplePageModule),
  },
  {
    path: "sample-page3",
    loadChildren: () => import("../../components/sample-page3/sample-page3.module").then((m) => m.SamplePage3Module),
  },
  {
    path: "ecommerce",
    loadChildren: () => import("../../components/e-commerce/e-commerce.module").then((m) => m.ECommerceModule),
    data: {
      breadcrumb: "Ecommerce",
    },    
  },
  
  {
    path: "inventory",
    loadChildren: () => import("../../components/inventory/inventory.module").then((m) => m.InventoryModule),
    data: {
      breadcrumb: "Inventory",
    },    
  },
  {
    path: "pricing",
    loadChildren: () => import("../../components/pricing/pricing.module").then((m) => m.PricingModule),
    data: {
      breadcrumb: "Pricing",
    },
  },
];