import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class SpinnerService{
    
    isLoading$ = new Subject<boolean>();
    
    show():void{
        console.log(true)
        this.isLoading$.next(true)
    }

    hide():void{
        console.log('Hide')
        this.isLoading$.next(false)
    }
}