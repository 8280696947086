import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, tap, switchMap, of, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private tokenKey = 'auth_token';
  private claimsKey = 'auth_claims';
  private refreshTokenUrl = 'https://api.example.com/refresh-token'; // URL de tu API para renovar el token

  constructor(
    private jwtHelper: JwtHelperService,
    private http: HttpClient) { }

  // Guardar el token en localStorage o sessionStorage
  saveToken(token: any): void {
    localStorage.setItem(this.tokenKey, token);
    // Decodificar y guardar los claims
    const claims = this.jwtHelper.decodeToken(token);
    if (claims) {
      localStorage.setItem(this.claimsKey, JSON.stringify(claims));
    }
  }

  // Obtener el token
  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  // Eliminar el token y los claims al cerrar sesión
  removeToken(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.claimsKey);
  }

  // Obtener los claims (payload) del token
  getTokenClaims(): any {
    const token = this.getToken();
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return this.jwtHelper.decodeToken(token);
    }
    return null;
  }

  // Comprobar si el usuario está autenticado
  isAuthenticated(): boolean {
    const token = this.getToken();
    return token ? !this.jwtHelper.isTokenExpired(token) : false;
  }

  // Comprobar si el token está próximo a expirar (e.g., faltan menos de 5 minutos)
  isTokenNearExpiration(): boolean {
    const token = this.getToken();
    if (token) {
      const expirationDate = this.jwtHelper.getTokenExpirationDate(token);
      if (expirationDate) {
        const currentTime = new Date().getTime();
        const timeRemaining = expirationDate.getTime() - currentTime;
        return timeRemaining < 5 * 60 * 1000; // Menos de 5 minutos
      }
    }
    return false;
  }

  // Renovar el token si está próximo a expirar
  renewToken(): Observable<boolean> {
    if (this.isTokenNearExpiration()) {
      return this.http.post<{ token: string }>(this.refreshTokenUrl, {}).pipe(
        tap(response => {
          this.saveToken(response.token);
        }),
        switchMap(() => of(true)),
        catchError(() => of(false)) // En caso de error, devolvemos false
      );
    }
    return of(true);
  }
}
