<!-- Page Header Start-->
<div class="page-main-header open">
    <div class="main-header-right row">
      <div class="main-header-left col-auto p-0 ">
        <div class="logo-wrapper">
          <a href="javascript:void(0)"><img src="assets/images/endless-logo.png" alt="" /></a>
        </div>
      </div>
  
      <div class="nav-right col">
        <ul class="nav-menus" [ngClass]="{ open: openNav }">
  
          <li class="onhover-dropdown stl-nav-01" >
            <a class="txt-dark" href="javascript:void(0)">
              <h6>Tipos de Negocio</h6>
            </a>
            <ul class="language-dropdown onhover-show-div p-20">
              <li>
                <a href="javascript:void(0)" data-lng="en" (click)="changeLanguage('en')"><i
                    class="flag-icon flag-icon-is"></i> Cafeterias</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="es" (click)="changeLanguage('es')"><i
                    class="flag-icon flag-icon-um"></i> Restaurantes</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')"><i
                    class="flag-icon flag-icon-uy"></i> Carnes</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="fr" (click)="changeLanguage('fr')"><i
                    class="flag-icon flag-icon-nz"></i> French</a>
              </li>
            </ul>
          </li>
          <li class="onhover-dropdown stl-nav-01" >
            <a class="txt-dark" href="javascript:void(0)">
              <h6>Módulos</h6>
            </a>
            <ul class="language-dropdown onhover-show-div p-20">
              <li>
                <a href="javascript:void(0)" data-lng="en" (click)="changeLanguage('en')"><i
                    class="flag-icon flag-icon-is"></i> Ventas</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="es" (click)="changeLanguage('es')"><i
                    class="flag-icon flag-icon-um"></i> Inventario</a>
              </li>
              <li>
                <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')"><i
                    class="flag-icon flag-icon-uy"></i> Reportes</a>
              </li>
            </ul>
          </li>
          <li class="stl-nav-01">
            <a class="txt-dark" href="javascript:void(0)">
              <h6><a routerLink="/web/pricing">Planes</a></h6>
            </a>
          </li>
          <li>
          </li>
          <li style="width: 70%;">
      
            <button class="btn btn-primary py-2 px-4 ms-3" routerLink="/full/authentication/login">Iniciar Sesión</button>
          </li>
  
        </ul>
        <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
          <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
        </div>
      </div>
    </div>
  </div>
  <!-- Page Header Ends -->



















