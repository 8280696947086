import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;

	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?: any) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: "/content/pricing", title: 'Dashboard', icon: 'home', type: 'link', active: false, badgeType: 'primary',
		},
		{
			title: 'Administración', icon: 'unlock', type: 'sub', active: false, children: [
				//{ path: 'authentication/login/image', title: 'Login', type: 'extTabLink' },
				{ path: '/content/admin/useradmin/index', title: 'Administradores', type: 'link' },
				{ path: '/content/admin/suscription/index', title: 'Suscripcion', type: 'link' },
				{ path: '/content/admin/distributor/index', title: 'Distribuidores', type: 'link' },
				//{ path: 'authentication/unlockuser', title: 'Bloqueo usuario', type: 'extTabLink' },
				//{ path: 'authentication/forgetpassword', title: 'Olvidó su contraseña', type: 'extTabLink' },
				//{ path: 'authentication/resetpassword', title: 'Restablecer la contraseña', type: 'extTabLink' }
			]
		},
		/*{
			path: "/ecommerce/products", title: 'Vender', icon: 'dollar-sign', type: 'link', active: false, badgeType: 'primary',
		},
		{
			title: 'Inventario', icon: 'shopping-bag', type: 'sub', active: false, badgeType: 'primary', children: [
				{ path: '/inventory/product/list', title: 'Productos', type: 'link' },
				{ path: '/inventory/category/list', title: 'Categorías', type: 'link' },
				/*{ path: '/ecommerce/product/list', title: 'Productos', type: 'link' },
				{ path: '/ecommerce/product-details/1', title: 'Detalle produto', type: 'link' },
				{ path: '/ecommerce/payment/detail', title: 'Detalles del pago', type: 'link' },
				//{ path: '/ecommerce/order', title: 'Historial de pedidos', type: 'link' }
			]
		},
		{
			title: 'Informes', icon: 'pie-chart', type: 'sub', active: false, badgeType: 'primary', children: [
				{ path: '/ecommerce/order', title: 'Historial de pedidos', type: 'link' }
			]
		},*/
		/*{
			title: "Sample Page",
			icon: "dollar-sign",
			type: "sub",
			active: true,
			children: [
				{ path: "/sample-page/sample-page1", title: "sample-page-1", type: "link" },
				{ path: "/sample-page/sample-page2", title: "sample-page-2", type: "link" },
			],
		},
		{ path: "/sample-page3", icon: "dollar-sign", title: "sample-page-3", type: "link" },*/	
		/*{
			
			title: 'Suscripciones', icon: 'dollar-sign', type: 'sub', active: false, badgeType: 'primary', children: [
				{ path: '/suscripcion/listsuscripcion', title: 'Listar suscripcion', type: 'link' },
			]
		},
		{
			title: 'Error Page', icon: 'alert-octagon', type: 'sub', active: false, children: [
				{ path: 'error/400', title: 'Error 400', type: 'extTabLink' },
				{ path: 'error/401', title: 'Error 401', type: 'extTabLink' },
				{ path: 'error/500', title: 'Error 500', type: 'extTabLink' },
			]
		},*/
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
