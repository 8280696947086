import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokeninterceptorService implements HttpInterceptor {


  constructor(private authService: AuthService) { }
  /*
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return this.authService.renewToken().pipe(
        switchMap((tokenRenewed: boolean) => {
          const token = this.authService.getToken();
          if (token) {
            const clonedRequest = req.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
            return next.handle(clonedRequest);
          } else {
            return next.handle(req);
          }
        })
      );
    }*/




  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string = ""; // Inicialización con un valor por defecto o vacío

    let tokenFromStorage = this.authService.getToken();
    if (tokenFromStorage !== null) {
      token = tokenFromStorage; // Asignar solo si tokenFromStorage no es null
    } else {
      // Manejar el caso donde tokenFromStorage es null, por ejemplo, proporcionando un valor predeterminado
      console.warn('El token en localStorage es nulo.');
    }

    let contentType = req.headers.get('Content-Type');

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    if (contentType) {
      headers.append('Content-Type', contentType);
    }
    const reqClone = req.clone({
      headers
    });
    return next.handle(reqClone);
  }

  /*constructor() { }*/


}
