<div class="dark-body-only" id="outer-container">
  <!-- page-wrapper Start-->
  <div class="page-wrapper" [ngClass]="customizer.data.settings.sidebar.type" id="canvas-bookmark">
    <app-header-web-page (rightSidebarEvent)="rightSidebar($event)"></app-header-web-page>
    <!-- Page Body Start-->
    <div class="page-body-wrapper" [ngClass]="customizer.data.settings.sidebar.body_type">

      <div class="page-body">
        <main>
          <div id="header-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img class="w-100" src="assets/images/carousel-1.jpg" alt="Image">
                <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div class="p-3" style="max-width: 900px;">
                    <h5 class="text-white text-uppercase mb-3 animated slideInDown">Stric Pos esta cambiando</h5>
                    <h1 class="display-1 text-white mb-md-4 animated zoomIn">Espera nuevas noticias!!!</h1>
                    <a href="quote.html" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">DEMO</a>
                                  <a href="" class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contactenos</a>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <img class="w-100" src="assets/images/carousel-2.jpg" alt="Image">
                <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                  <div class="p-3" style="max-width: 900px;">
                    <h5 class="text-white text-uppercase mb-3 animated slideInDown">Creative & Innovative</h5>
                    <h1 class="display-1 text-white mb-md-4 animated zoomIn">Creative & Innovative Digital Solution</h1>
                    <a href="quote.html" class="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">DEMO</a>
                    <a href="" class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">Contactenos</a>
                  </div>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>

          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
      <!-- footer start-->
      <footer class="footer">
        <app-footer-web-page></app-footer-web-page>
      </footer>
      <!-- footer End-->
    </div>
    <!-- Page Body End-->
  </div>
  <!-- page-wrapper End-->
</div>