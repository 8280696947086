import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSearch'
})
export class FilterPipe implements PipeTransform {
  transform<T extends Record<string, any>>(items: T[], searchTerm: string): T[] {
    if (!items || !searchTerm) {
      return items;
    }

    searchTerm = searchTerm.toLowerCase();

    return items.filter(item => {
      return Object.keys(item).some(key => {
        const value = item[key];
        return value !== null && value !== undefined && value.toString().toLowerCase().includes(searchTerm);
      });
    });
  }
}
