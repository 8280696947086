import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { ContentLayoutComponent } from "./shared/components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./shared/components/layout/full-layout/full-layout.component";
import { content } from "./shared/routes/content-routes";
import { full } from "./shared/routes/full.routes";
import { LayoutWebPageComponent } from "./shared/components/layout-web-page/layout-web-page.component";
import { contentwebpage } from "./shared/routes/content.routes.web-page";
import { AuthGuard } from "./interceptors/AuthGuard.interceptor";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/web",
    pathMatch: "full",
  },
  {
    path: "web",
    component: LayoutWebPageComponent,
    children: contentwebpage
  },
  {
    path: "content",
    component: ContentLayoutComponent,
    children: content,
    canActivate: [AuthGuard]    
  },
  {
    path: "full",
    component: FullLayoutComponent,
    children: full,
  },
  //Route de la pagina web  
  {
    path: "**",
    redirectTo: "/web",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      useHash: true 
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
